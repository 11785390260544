import styles from "./CompleteAnswer.module.scss";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const themeOutlined = createTheme({
    palette: {
      primary: {
        main: "#ffffff",
      },
    },
  });

export const CompleteAnswer = () => {
  return (
    <>
        <div className={styles["header"]}>
          <div className={styles["header-logo"]}>
            <img
                src="./images/livepoll-logo-primary.svg"
                className={styles["header-logo-img"]}
                alt="img"
            />
          </div>
        </div>
        <div className={styles["body"]}>
            <div className={styles["content-wrapper"]}>
                <div className={styles["content1"]}>
                    <p className={styles["content1-text"]}>回答ありがとうございました！</p>
                </div>
                <div className={styles["content2"]}>
                    <p className={styles["content2-text"]}>アンケート作成ならlivepoll</p>
                </div>
                <div className={styles["content3"]}>
                    <ThemeProvider theme={themeOutlined}>
                        <Button
                        variant="outlined"
                        size="large"
                        component={Link}
                        to="/"
                        >
                        トップへもどる
                        </Button>
                    </ThemeProvider>
                </div>
            </div>
        </div>
        
    </>
  );
};
