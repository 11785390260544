import styles from "./Contact.module.scss";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Button } from "@mui/material";

const themeTextField = createTheme({
    palette: {
      primary: {
        main: "#ff8c00",
      },
    },
  });

const themeContained = createTheme({
    palette: {
        primary: {
        main: "#ff8c00",
        contrastText: "#fff",
        },
    },
});

export const Contact = () => {
    const [email, setEmail] = useState('');
    const [title, setTitle] = useState('');
    const [details, setDetails] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    useEffect(() => {
        setIsEmailValid(emailRegex.test(email));
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [email]);
    
      useEffect(() => {
        setIsButtonEnabled(email !== '' && title !== '' && details !== '' && isEmailValid);
      }, [email, title, details, isEmailValid]);
    return (
        <>
            <div className={styles["header"]}>
                <div className={styles["header-logo"]}>
                <Link to="/" className={styles["header-logo-link"]}>
                    <img
                    src="./images/livepoll-logo-secondary.svg"
                    className={styles["header-logo-img"]}
                    alt="img"
                    />
                </Link>
                </div>
            </div>
            <div className={styles["content1"]}>
                <div className={styles["content1-text"]}>
                    <p>コンタクト</p>
                </div>
            </div>
            <div className={styles["content2"]}>
                <ThemeProvider theme={themeTextField}>
                <TextField
                    fullWidth
                    required
                    id="standard-basic"
                    label="メールアドレス"
                    variant="standard"
                    className={styles["text-input"]}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!isEmailValid && email !== ''}
                />
                </ThemeProvider>
            </div>
            <div className={styles["content3"]}>
            <ThemeProvider theme={themeTextField}>
                <TextField
                    fullWidth
                    required
                    id="standard-basic"
                    label="問い合わせタイトル"
                    variant="standard"
                    className={styles["text-input"]}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                </ThemeProvider>
            </div>
            <div className={styles["content4"]}>
            <ThemeProvider theme={themeTextField}>
                <TextField
                    fullWidth
                    required
                    id="standard-basic"
                    label="問い合わせ詳細"
                    variant="standard"
                    className={styles["text-input"]}
                    multiline
                    rows={10}
                    value={details}
                    onChange={(e) => setDetails(e.target.value)}
                />
                </ThemeProvider>
            </div>
            <div className={styles["content5"]}>
            <ThemeProvider theme={themeContained}>
                <div className={styles["content5-send-button"]}>
                    <Button
                    variant="contained"
                    size="large"
                    disableElevation
                    component={Link}
                    to="/share-poll"
                    disabled={!isButtonEnabled}
                    >
                    送信
                    </Button>
                </div>
                </ThemeProvider>
            </div>
            <div className={styles["footer"]}>
                <div className={styles["footer-top"]}>
                <Link to="/" className={styles["footer-top-link"]}>
                    <span className={styles["footer-top-text"]}>
                    ＜Topへ戻る
                    </span>
                </Link>
                </div>
                <div className={styles["footer-privacy-terms"]}>
                <Link to="/privacy-terms" className={styles["footer-privacy-terms-link"]}>
                    <span className={styles["footer-privacy-terms-text"]}>
                    プライバシーポリシー/規約
                    </span>
                </Link>
                </div>
            </div>
        </>
    );
}