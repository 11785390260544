import styles from "./CreatePoll.module.scss";
import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Stack, Chip } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const themeContained = createTheme({
  palette: {
    primary: {
      main: "#ff8c00",
      contrastText: "#fff",
    },
  },
});

const themeTextField = createTheme({
  palette: {
    primary: {
      main: "#ff8c00",
    },
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#ff8c00",
    },
  },
});

const RadioChip = styled(Chip)(({ theme, selected }) => ({
  border: selected ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.grey[400]}`,
  backgroundColor: selected ? theme.palette.primary.light : theme.palette.background.paper,
  color: selected ? theme.palette.primary.contrastText : theme.palette.text.primary,
}));


export const CreatePoll = () => {
  const [isFormButtonEnabled, setFormButtonEnabled] = useState(false);
  const [isEmailChecked, setEmailChecked] = useState(false);
  const [email, setEmail] = useState('');
  const [question, setQuestion] = useState('');
  const [choices, setChoices] = useState(Array(6).fill(''));
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isConfirmationCodeValid, setIsConfirmationCodeValid] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const validateForm = () => {
    const areChoicesValid = choices.slice(0, 2).every(choice => choice.trim() !== '');
    const isQuestionValid = question.trim() !== '';
    const isConfirmationValid = isEmailChecked ? isConfirmationCodeValid : true;
    const isDeadlineSelected = selectedValue !== null;
    setFormButtonEnabled(isEmailChecked && isEmailValid && isQuestionValid && areChoicesValid && isConfirmationValid && isDeadlineSelected);
  };

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, question, choices, isEmailChecked, isEmailValid, confirmationCode, isConfirmationCodeValid, selectedValue]);

  const handleEmailCheck = async () => {
    if (isEmailValid) {
      try {
        const response = await fetch('https://sendconfirmationcode-7flhdjuada-uc.a.run.app', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }), // メールアドレスを送信
        });
  
        if (response.ok) {
          setEmailChecked(true);
        } else {
          console.error('メール確認に失敗しました');
        }
      } catch (error) {
        console.error('エラーが発生しました:', error);
      }
    }
  };

  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setEmail(event.target.value);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailPattern.test(emailValue));
  };

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleChoiceChange = (index, value) => {
    const newChoices = [...choices];
    newChoices[index] = value;
    setChoices(newChoices);
  };

  const handleConfirmationCodeChange = (event) => {
    const code = event.target.value;
    setConfirmationCode(code);
    const confirmationCodePattern = /^\d{6}$/;
    setIsConfirmationCodeValid(confirmationCodePattern.test(code));
  };

  const handleChipClick = (value) => {
    setSelectedValue(value);
  };

  const handleSubmit = async () => {
    if (isFormButtonEnabled) {
      try {
        const response = await fetch('https://saveformdata-7flhdjuada-uc.a.run.app', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            confirmationCode,
            question,
            option1: choices[0],
            option2: choices[1],
            option3: choices[2],
            option4: choices[3],
            option5: choices[4],
            option6: choices[5],
            deadline: selectedValue,
          }),
        });
        console.log(JSON.stringify({
          email,
          confirmationCode,
          question,
          option1: choices[0],
          option2: choices[1],
          option3: choices[2],
          option4: choices[3],
          option5: choices[4],
          option6: choices[5],
          deadline: selectedValue,
        }))
        const data = response;
        console.log('Response status:', response.status);
        console.log('Response data:', data);
        if (response.ok) {
          const data = await response.json();
          const formEditingId = data.formEditingId;
          console.log("ここ！！！！")
          console.log(data)
          // publicUuidを使って画面遷移
          navigate(`/share-poll/?formEditingId=${formEditingId}`);
        } else {
          console.error('フォームデータの送信に失敗しました');
        }
      } catch (error) {
        console.error('エラーが発生しました:', error);
      }
    }
  };

  const navigate = useNavigate();

  return (
    <>
      <div className={styles["header"]}>
        <div className={styles["header-logo"]}>
          <img
            src="./images/livepoll-logo-secondary.svg"
            className={styles["header-logo-img"]}
            alt="img"
          />
        </div>
      </div>
      <div className={styles["content1"]}>
        <p className={styles["content1-text"]}>*は必須</p>
      </div>
      <div className={styles["content2"]}>
        <div className={styles["content2-email-area"]}>
        <ThemeProvider theme={themeTextField}>
          <TextField
            fullWidth
            required
            id="standard-basic"
            label="メールアドレス"
            variant="standard"
            className={styles["content2-email-input"]}
            value={email}
            onChange={handleEmailChange}
            disabled={isEmailChecked}
            error={!isEmailValid && email !== ''}
            
          />
        </ThemeProvider>
        </div>
        <div className={styles["content2-button-area"]}>
        <ThemeProvider theme={themeContained}>
            <Button
              variant="contained"
              size="small"
              disableElevation
              onClick={handleEmailCheck}
              disabled={isEmailChecked || !isEmailValid}
            >
            メールを確認*
            </Button>
        </ThemeProvider>
        </div>
      </div>
      <div className={styles["content3"]}>
      {isEmailChecked && (
          <div className={styles["content2-confirmation-code-area"]}>
            <ThemeProvider theme={themeTextField}>
              <TextField
                fullWidth
                required
                id="confirmation-code"
                label="確認コードを入力"
                variant="standard"
                className={styles["content2-confirmation-code-input"]}
                value={confirmationCode}
                onChange={handleConfirmationCodeChange}
                helperText={!isConfirmationCodeValid && confirmationCode !== '' ? '送信されたメールに記載の6桁の数字を入力してください' : ''}
              />
            </ThemeProvider>
          </div>
        )}
      </div>
      <div className={styles["content4"]}>
        <ThemeProvider theme={themeTextField}>
          <TextField
            fullWidth
            required
            id="standard-basic"
            label="質問 (50文字以内)"
            variant="standard"
            className={styles["content4-question-title"]}
            value={question}
            onChange={handleQuestionChange}
          />
        </ThemeProvider>
      </div>
      <div className={styles["content5"]}>
        <p className={styles["content5-text"]}>選択肢</p>
      </div>
      <div className={styles["content6"]}>
        <ThemeProvider theme={themeTextField}>
          {[...Array(6)].map((_, index) => (
            <div className={styles["content6-choice-block"]} key={index}>
              <TextField
                fullWidth
                id={`standard-basic-${index}`}
                label={`選択肢${index + 1}`}
                variant="standard"
                className={styles[`content6-choices${index + 1}`]}
                required={index < 2}
                value={choices[index]}
                onChange={(e) => handleChoiceChange(index, e.target.value)}
              />
            </div>
          ))}
        </ThemeProvider>
      </div>
      <div className={styles["content7"]}>
        <p className={styles["content7-text"]}>締め切り*</p>
      </div>
      <div className={styles["content8"]}>
      <ThemeProvider theme={theme}>
        <Stack direction="row" spacing={1}
        sx={{
          flexWrap: 'wrap',
          justifyContent: "flex-start",
          gap: 1,
        }}
        >
          {[
              { label: '12時間後', value: 'h12' },
              { label: '24時間後', value: 'h24' },
              { label: '2日後', value: 'd2' },
              { label: '3日後', value: 'd3' },
              { label: '5日後', value: 'd5' },
              { label: '1週間後', value: 'w1' },
              { label: 'なし(30日後)', value: 'd30' }
            ].map((option) => (
              <RadioChip
                key={option.value}
                label={option.label}
                clickable
                onClick={() => handleChipClick(option.value)}
                selected={selectedValue === option.value}
              />
            ))}
        </Stack>
        </ThemeProvider>
      </div>
      <div className={styles["content9"]}>
        <ThemeProvider theme={themeContained}>
          <div className={styles["content8-send-poll-button"]}>
            <Button
              variant="contained"
              size="large"
              disableElevation
              component={Link}
              to="#"
              onClick={handleSubmit}
              disabled={!isFormButtonEnabled}
            >
              フォームを作成
            </Button>
          </div>
        </ThemeProvider>
      </div>
    </>
  );
};
