import styles from "./PrivacyTerms.module.scss";
import { Link } from "react-router-dom";

export const PrivacyTerms = () => {
    return (
        <>
        <div className={styles["header"]}>
            <div className={styles["header-logo"]}>
                <Link to="/" className={styles["header-logo-link"]}>
                    <img
                    src="./images/livepoll-logo-secondary.svg"
                    className={styles["header-logo-img"]}
                    alt="img"
                    />
                </Link>
            </div>
        </div>
        <div className={styles["content1-wrapper"]}>
        <div className={styles["content1"]}>
            <h1>プライバシーポリシー</h1>
                <h2>メールアドレスの利用目的</h2>
                <p>livepollではサービスの利用、お問い合わせの際にメールアドレスをご入力いただいております。</p>
                <p>収集したメールアドレスはサービス提供のため、もしくはお問い合わせの返信に利用させていただくものであり、これら以外の目的では利用いたしません。</p>
                <h2>IPアドレスの収集</h2>
                <p>livepollのサービス利用、お問い合わせの際にIPアドレスを収集しています。</p>
                <p>これはスパムへの対応を行うために利用させていただくものであり、これら以外の目的では利用いたしません。</p>
                <h2>収集データの第三者への提供</h2>
                <p>livepollで収集したメールアドレス、IPアドレスを含む収集データは以下に定める場合を除き、第三者へ提供しません。</p>
                <p>・国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき。</p>

            <h1>規約</h1>
                <h2>ユーザー生成コンテンツの削除</h2>
                <p>livepollではユーザーが作成した文章等（以下「ユーザー生成コンテンツ」といいます。）を公開する機能を含むサービスを提供しています。</p>
                <p>livepollは以下に定める場合において、ユーザー生成コンテンツを削除する場合があります。</p>
                <p>・誹謗中傷・脅迫・他者の権利侵害・詐欺を含む、もしくは悪質性が高いとlivepollが判断したコンテンツ。</p>
                <p>・法律・法令に基づき削除する必要のあるコンテンツ。</p>
                <p>・メンテナンスやサービスの提供終了に伴う対応。</p>
                <h2>スパムへの対応</h2>
                <p>悪質性が高いと判断したユーザーの行為（以下「スパム」といいます）に対し、livepollは以下の対応を行います。</p>
                <p>・当該ユーザーのアクセスをブロック。</p>
            <h1>免責事項</h1>
                <p>livepollでは、掲載するコンテンツ、ユーザー生成コンテンツの情報の正確性を保証しません。</p>
                <p>これらによって生じた損害等の一切の責任を負いません。</p>
        </div>
        </div>
        <div className={styles["footer"]}>
                <div className={styles["footer-top"]}>
                <Link to="/" className={styles["footer-top-link"]}>
                    <span className={styles["footer-top-text"]}>
                    ＜Topへ戻る
                    </span>
                </Link>
                </div>
                <div className={styles["footer-contact"]}>
                <Link to="/contact" className={styles["footer-contact-link"]}>
                    <span className={styles["footer-contact-text"]}>
                    コンタクト
                    </span>
                </Link>
                </div>
            </div>
        </>
    );
}