//import logo from './logo.svg';
import { Routes, Route } from "react-router-dom";
import { Top } from "./components/Top";
import { CreatePoll } from "./components/CreatePoll";
import { SharePoll } from "./components/SharePoll";
import { SendAnswer } from "./components/SendAnswer";
import { CompleteAnswer } from "./components/CompleteAnswer";
import { Contact } from "./components/Contact";
import { PrivacyTerms } from "./components/PrivacyTerms";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Top />} />
        <Route path="/create-poll" element={<CreatePoll />} />
        <Route path="/share-poll" element={<SharePoll />} />
        <Route path="/send-answer" element={<SendAnswer />} />
        <Route path="/complete-answer" element={<CompleteAnswer />} />
        <Route path="/contact" element={< Contact />} />
        <Route path="/privacy-terms" element={< PrivacyTerms />} />
      </Routes>
    </div>
  );
}

export default App;
