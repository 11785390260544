import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from 'react';
import { doc, onSnapshot } from "firebase/firestore";
import styles from "./SharePoll.module.scss";
import { BarChart } from '@mui/x-charts/BarChart';
import { QRCodeSVG } from 'qrcode.react';
import { auth, signInAnonymously, db } from './firebaseConfig'; // Firebase初期化設定をインポート
import { CircularProgress } from "@mui/material"; // スピナーの追加

export const SharePoll = () => {
  const [searchParams] = useSearchParams();
  const formEditingId = searchParams.get("formEditingId");
  const [pollData, setPollData] = useState(null);
  const [loading, setLoading] = useState(true); // ローディング状態を追加
  const [setUser] = useState(null);

  const anonAuth = useCallback(async () => {
    try {
      let userCredential = await signInAnonymously(auth);
      setUser(userCredential.user);
    } catch (e) {
      switch (e.code) {
        case "operation-not-allowed":
          break;
        default:
      }
    }
  }, [setUser]);

  useEffect(() => {
    if (!formEditingId) return;

    // Firestoreのリアルタイムリスナーを設定
    const unsubscribe = onSnapshot(
      doc(db, "publicFormData", formEditingId),
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setPollData({
            question: data.question,
            options: data.options,
            polls: data.polls,
            formId: data.formId,
            expirationDate: data.expirationDate
          });
          setLoading(false); // データが取得できたらローディングを終了
        } else {
          console.error("Document not found");
          setLoading(false); // データがない場合もローディングを終了
        }
      },
      (error) => {
        console.error("リアルタイムデータ取得中にエラーが発生しました:", error);
        setLoading(false); // エラー発生時もローディングを終了
      }
    );

    // クリーンアップ関数
    return () => unsubscribe();
  }, [formEditingId]);

  useEffect(() => {
    anonAuth();
  }, [anonAuth]);

  if (loading) {
    return <CircularProgress />; // ローディング中はスピナーを表示
  }

  if (!pollData) {
    return <p>データが見つかりません。</p>; // pollDataがない場合の表示
  }

  // optionsとpollsを取得
  const optionKeys = ["option1", "option2", "option3", "option4", "option5", "option6"];
  const optionValues = optionKeys.map((key) => pollData.options[key] ? pollData.options[key] : "");
  const pollCounts = optionValues.map((_, index) => {
    return pollData.polls[`option${index + 1}Polls`] !== undefined
      ? pollData.polls[`option${index + 1}Polls`]
      : 0;
  });

  return (
    <>
      <div className={styles["header"]}>
        <div className={styles["header-logo"]}>
          <img
            src="./images/livepoll-logo-secondary.svg"
            className={styles["header-logo-img"]}
            alt="img"
          />
        </div>
      </div>
      <div className={styles["content1"]}>
        <p className={styles["content1-text"]}>{pollData.question}</p>
      </div>
      <div className={styles["body"]}>
        <div className={styles["content2"]}>
          <BarChart
            series={[
              { data: pollCounts, color: '#ff8c00' },
            ]}
            yAxis={[{ data: optionValues, scaleType: "band" }]}
            margin={{ top: 10, bottom: 30, left: 100, right: 10 }}
            layout="horizontal"
          />
        </div>
        <div className={styles["content3-wrapper"]}>
          <p>QRコードを読み込んでアンケートに答える</p>
          <div className={styles["content3"]}>
            <div className={styles["content3-img"]}>
              <QRCodeSVG
                value={`https://live-poll.net/send-answer/?formId=${pollData.formId}`}
                size={500}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                imageSettings={{
                  x: undefined,
                  y: undefined,
                  excavate: true,
                }}
                style={{ width: "80%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
