import styles from "./Top.module.scss";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const themeContained = createTheme({
  palette: {
    primary: {
      main: "#ff8c00",
      contrastText: "#fff",
    },
  },
});

const themeOutlined = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
  },
});

export const Top = () => {
  return (
    <>
      <div className={styles["header"]}>
        <div className={styles["header-logo"]}>
          <Link to="/" className={styles["header-logo-link"]}>
            <img
              src="./images/livepoll-logo-primary.svg"
              className={styles["header-logo-img"]}
              alt="img"
            />
          </Link>
        </div>
        <ThemeProvider theme={themeContained}>
          <div className={styles["header-create-poll-button"]}>
            <Button
              variant="contained"
              size="large"
              disableElevation
              component={Link}
              to="/create-poll"
            >
              フォームを作成
            </Button>
          </div>
        </ThemeProvider>
      </div>

      <div className={styles["content1"]}>
        <div className={styles["content1-headline"]}>
          <p className={styles["content1-headline-text"]}>
            アカウント不要
            <br />
            １分でアンケートを作成
            <br />
            その場で結果を表示
          </p>
        </div>
        <div className={styles["content1-img-area"]}>
          <img
            src="./images/screenshot1.png"
            className={styles["content1-img"]}
            alt="img"
          />
          <img
            src="./images/screenshot1.png"
            className={styles["content1-img"]}
            alt="img"
          />
          <img
            src="./images/screenshot1.png"
            className={styles["content1-img"]}
            alt="img"
          />
        </div>
        <ThemeProvider theme={themeOutlined}>
          <div className={styles["content1-create-poll-button"]}>
            <Button
              variant="outlined"
              size="large"
              component={Link}
              to="/create-poll"
            >
              フォームを作成
            </Button>
          </div>
        </ThemeProvider>
      </div>
      <div className={styles["content2"]}>
        <img
            src="./images/livepoll-logo-primary.svg"
            className={styles["content2-logo-img"]}
            alt="img"
          />
        <div className={styles["content2-headline"]}>
          <p className={styles["content2-headline-text"]}>機能</p>
        </div>
        <div className={styles["content2-block-column"]}>
          <div className={styles["content2-block-area"]}>
            <div className={styles["content2-block"]}>
              <p className={styles["content2-block-text"]}>QRコード共有</p>
            </div>
            <div className={styles["content2-block"]}>
              <p className={styles["content2-block-text"]}>
                集計結果の
                <br />
                リアルタイム
                <br />
                表示
              </p>
            </div>
          </div>
          <div className={styles["content2-block-area"]}>
            <div className={styles["content2-block"]}>
              <p className={styles["content2-block-text"]}>
                AES256による
                <br />
                データの暗号化
              </p>
            </div>
            <div className={styles["content2-block"]}>
              <p className={styles["content2-block-text"]}>
                モバイル・PCの
                <br />
                フルサポート
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["content3"]}>
        <div className={styles["content3-headline"]}>
          <p className={styles["content3-headline-text"]}>
            イベントでのアンケートに
          </p>
        </div>
        <div className={styles["content3-block"]}>
          <div className={styles["content3-img-area"]}>
            <img
              src="./images/no-image.jpg"
              className={styles["content3-img"]}
              alt="img"
            />
          </div>
          <div className={styles["content3-text-area"]}>
            <p className={styles["content3-text"]}>
              アンケートの共有QR
              <br />
              コードと集計結果が同時に
              <br />
              表示されるので、
              <br />
              その場でサクッと参加者に
              <br />
              質問できます。
            </p>
          </div>
        </div>
        <div className={styles["content3-headline"]}>
          <p className={styles["content3-headline-text"]}>カジュアルな投票に</p>
        </div>
        <div className={styles["content3-block"]}>
          <div className={styles["content3-text-area"]}>
            <p className={styles["content3-text"]}>
              必要なのはメールアドレス
              <br />
              だけ。
              <br />
              カジュアルな投票の作成に
              <br />
              最適です。
            </p>
          </div>
          <div className={styles["content3-img-area"]}>
            <img
              src="./images/no-image.jpg"
              className={styles["content3-img"]}
              alt="img"
            />
          </div>
        </div>
      </div>
      <div className={styles["content4"]}>
        <div className={styles["content4-icon-area"]}>
          <img
            src="./images/icon.png"
            className={styles["content4-icon"]}
            alt="icon"
          />
        </div>
        <div className={styles["content4-block"]}>
          <div className={styles["content4-block-headline"]}>
            <p className={styles["content4-block-headline-text"]}>
              QRコード共有
            </p>
          </div>
          <div className={styles["content4-text-area"]}>
            <p className={styles["content4-text"]}>
              アンケート結果はシェアしましょう！
              <br />
              QRコードを読み込むと回答画面が表示されます。
              <br />
              リンク(URL)による共有も可能です。
            </p>
          </div>
        </div>
        <div className={styles["content4-icon-area"]}>
          <img
            src="./images/icon.png"
            className={styles["content4-icon"]}
            alt="icon"
          />
        </div>
        <div className={styles["content4-block"]}>
          <div className={styles["content4-block-headline"]}>
            <p className={styles["content4-block-headline-text"]}>
              集計結果のリアルタイム表示
            </p>
          </div>
          <div className={styles["content4-text-area"]}>
            <p className={styles["content4-text"]}>
              集計結果はリアルタイムでグラフに表示されます。
              <br />
              グラフの横、もしくは下に回答QRコードが表示
              <br />
              されるため、１画面で完結します。
            </p>
          </div>
        </div>
        <div className={styles["content4-icon-area"]}>
          <img
            src="./images/icon.png"
            className={styles["content4-icon"]}
            alt="icon"
          />
        </div>
        <div className={styles["content4-block"]}>
          <div className={styles["content4-block-headline"]}>
            <p className={styles["content4-block-headline-text"]}>
              AES256によるデータの暗号化
            </p>
          </div>
          <div className={styles["content4-text-area"]}>
            <p className={styles["content4-text"]}>
              送信したメールアドレスやアンケートデータは
              <br />
              通信経路・サーバー上の全てで暗号化されます。
              <br />
              共有しないデータはユーザー自身しか
              <br />
              閲覧できません。
            </p>
          </div>
        </div>
        <div className={styles["content4-icon-area"]}>
          <img
            src="./images/icon.png"
            className={styles["content4-icon"]}
            alt="icon"
          />
        </div>
        <div className={styles["content4-block"]}>
          <div className={styles["content4-block-headline"]}>
            <p className={styles["content4-block-headline-text"]}>
              モバイル・PCのフルサポート
            </p>
          </div>
          <div className={styles["content4-text-area"]}>
            <p className={styles["content4-text"]}>
              アンケートの作成も共有も、
              <br />
              ご自身のスマホやパソコンで完結します。
            </p>
          </div>
        </div>
      </div>
      <div className={styles["content5"]}>
        <div className={styles["content5-headline"]}>
          <p className={styles["content5-headline-text"]}>Q&A</p>
        </div>
        <div className={styles["content5-block"]}>
          <Accordion elevation={0} className={styles["content5-acordion"]}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              データの保存期間はどれぐらいですか？
            </AccordionSummary>
            <AccordionDetails>
              作成したアンケートは31日間保存されます。
              <br />
              保存期間を過ぎるとアンケートと集計結果のどちらも削除されます。
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} className={styles["content5-acordion"]}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              livepollが管理のためにユーザーデータにアクセスすることはありますか？
            </AccordionSummary>
            <AccordionDetails>
              ありません。
              <br />
              送信されたデータは暗号化されており、livepollは如何なる場合でもデータを見ることができません。
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <div className={styles["content6"]}>
        <div className={styles["content6-headline"]}>
          <p className={styles["content6-headline-text"]}>使ってみませんか？</p>
        </div>
        <ThemeProvider theme={themeContained}>
          <div className={styles["content6-create-poll-button"]}>
            <Button
              variant="contained"
              size="large"
              disableElevation
              component={Link}
              to="/create-poll"
            >
              フォームを作成
            </Button>
          </div>
        </ThemeProvider>
      </div>
      <div className={styles["footer"]}>
        <div className={styles["footer-contact"]}>
          <Link to="/contact" className={styles["footer-contact-link"]}>
            <span className={styles["footer-contact-text"]}>
              コンタクト
            </span>
          </Link>
        </div>
        <div className={styles["footer-privacy-terms"]}>
          <Link to="/privacy-terms" className={styles["footer-privacy-terms-link"]}>
            <span className={styles["footer-privacy-terms-text"]}>
              プライバシーポリシー/規約
            </span>
          </Link>
        </div>
      </div>
    </>
  );
};
