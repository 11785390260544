import styles from "./SendAnswer.module.scss";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useMediaQuery } from '@mui/material';
import { Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const theme = createTheme({
    palette: {
        primary: {
            main: "#ff8c00",
            contrastText: "#fff",
        },
    },
});

export const SendAnswer = () => {
    const isDesktop = useMediaQuery('(min-width:1000px)');
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const formId = searchParams.get("formId"); // formIdをクエリパラメータから取得

    const [question, setQuestion] = useState('');
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // 質問とオプションの取得
        fetch(`https://readformdata2-7flhdjuada-uc.a.run.app/?formId=${formId}`)
            .then(response => {
                if (!response.ok) throw new Error("データの取得に失敗しました。");
                return response.json();
            })
            .then(data => {
                setQuestion(data.question);
                setOptions([
                    { value: "option1", label: data.options.option1 },
                    { value: "option2", label: data.options.option2 },
                    { value: "option3", label: data.options.option3 },
                    { value: "option4", label: data.options.option4 },
                    { value: "option5", label: data.options.option5 },
                    { value: "option6", label: data.options.option6 }
                ].filter(option => option.label)); // labelが空でないもののみを表示
                setLoading(false);
            })
            .catch(() => {
                setError("データの取得に失敗しました。");
                setLoading(false);
            });
    }, [formId]);

    const handleSubmit = () => {
        if (!selectedOption) {
            alert("回答を選択してください。");
            return;
        }
        

        // 回答データを送信
        fetch("https://saveanswer-7flhdjuada-uc.a.run.app", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                formId: formId,
                option: selectedOption
            })
        })
        .then(() => {
            navigate("/complete-answer");
        })
        .catch(() => {
            setError("回答の送信に失敗しました。もう一度お試しください。");
        });
    };

    return (
        <ThemeProvider theme={theme}>
            <div className={styles["header"]}>
                <div className={styles["header-logo"]}>
                    <img
                        src="./images/livepoll-logo-secondary.svg"
                        className={styles["header-logo-img"]}
                        alt="img"
                    />
                </div>
            </div>
            <div className={styles["content1"]}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Typography variant="h5" className={styles["content1-text"]}>
                        {error || question}
                    </Typography>
                )}
            </div>
            <div className={styles["content2"]}>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                    >
                        {options.map((option) => (
                            <div
                                key={option.value}
                                className={styles.radioWrapper}
                                onClick={() => setSelectedOption(option.value)}
                            >
                                <FormControlLabel
                                    value={option.value}
                                    control={<Radio id={option.value} />}
                                    label={option.label}
                                    sx={{
                                        border: "2px solid",
                                        borderColor: 'primary.main',
                                        m: "5px",
                                        p: "20px 20px 20px 0px",
                                        borderRadius: "15px",
                                        width: isDesktop ? '40vw' : '80vw',
                                    }}
                                />
                            </div>
                        ))}
                    </RadioGroup>
                </FormControl>
            </div>
            <div className={styles["content3"]}>
                <Button
                    variant="contained"
                    size="large"
                    disableElevation
                    onClick={handleSubmit}
                    disabled={!selectedOption || loading}
                >
                    回答する！
                </Button>
            </div>
        </ThemeProvider>
    );
};
