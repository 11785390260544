// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";

// Firebaseコンソールから取得した設定情報を入力します
const firebaseConfig = {
    apiKey: "AIzaSyBS-deMk5D3IJ4m7788C7vmyoVTDdgM0Z8",
    authDomain: "live-poll-project.firebaseapp.com",
    projectId: "live-poll-project",
    storageBucket: "live-poll-project.appspot.com",
    messagingSenderId: "360120848435",
    appId: "1:360120848435:web:a693ff750717f7ddaa5346",
    measurementId: "G-WX5H3FKN8N"
};

// Firebaseの初期化
const app = initializeApp(firebaseConfig);

// Firestoreのインスタンスをエクスポート
const db = getFirestore(app);
const auth = getAuth(app);

export { app, db, auth, signInAnonymously };